import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { rhythm } from "../utils/typography"

const TagPageTemplate = ({data, location}) => {

  const splitPath = location.pathname.split('/');
  const tagName = splitPath[splitPath.length-1];
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <header>
        <h1>{tagName}</h1>
      </header>
      {data.postsInTag.nodes.map(( node ) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
  </Layout>
  )
};

export default TagPageTemplate

export const pageQuery = graphql `query PostsByTag($tag: String!) {
    site {
      siteMetadata {
        title
      }
    }
  postsInTag: allMarkdownRemark(filter: {frontmatter: {tags: { eq: $tag }}}) {
    nodes {
      frontmatter {
        description
        date
        layout
        tags
        title
      }
      fields {
        slug
      }
    }
  }
}
`
